// body {
//     background: #e2e1e0;
//   }

$transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

/* Card */
$card-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$card-shadow-hover: 0 14px 28px rgba(0, 0, 0, 0.25),
  0 10px 10px rgba(0, 0, 0, 0.22);

.card {
  transition: $transition;
  box-shadow: $card-shadow;
  background: #fff;
  margin: 20px 10px;
  cursor: pointer;
  &:hover {
    box-shadow: $card-shadow-hover;
  }
}

/* Selectable */
$check-size: 20px;
$selected-color: #0d6efd;
// old color: #44aadd

.card .selectable {
  position: relative;
  transition: $transition;
  border: 4px solid transparent;
  .check {
    transition: $transition;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: $check-size;
    height: $check-size;
    &:before {
      transition: $transition;
      content: "";
      border: $check-size solid;
      border-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
    }
    .checkmark {
      display: block;
      font: $check-size sans-serif;
      line-height: $check-size;
      text-align: center;
      color: transparent;
    }
  }

  &.selected {
    border-color: $selected-color;
    .check {
      &:before {
        border-color: $selected-color $selected-color rgba(0, 0, 255, 0)
          rgba(255, 0, 0, 0);
      }
      .checkmark {
        color: #fff;
      }
    }
  }
}

.content {
  .title,
  .description {
    margin: 0;
    padding: 4px;
  }
  padding: 24px;
}

.column {
  & > .title {
    text-align: center;
  }
  float: left;
  width: 100%;
}

// button.card {
//   display: block;
//   cursor: pointer;
//   width: 180px;
//   margin: 20px auto;
//   text-align: center;
//   padding: 16px;
//   border-color: transparent;
//   border-radius: 10px;
//   background: $selected-color !important;
//   color: #fff;
//   text-transform: uppercase;
//   font-weight: bold;
//   outline: none;
//   &:focus{
//     box-shadow: $card-shadow;
//   }
// }

// button.bottom_navbar {
//   display: block;
//   cursor: pointer;
//   // width: 180px;
//   margin: 40px auto;
//   text-align: center;
//   padding: 12px;
//   border-color: transparent;
//   border-radius: 10px;
//   background: $selected-color !important;
//   color: #fff;
//   text-transform: capitalize;
//   font-weight: bold;
//   outline: none;
//   &:focus {
//     box-shadow: $card-shadow;
//   }
//   margin-inline: 10px;
//   font-size: 1rem;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// button.bottom_navbar_submit {
//   display: block;
//   cursor: pointer;
//   // width: 180px;
//   margin: 40px auto;
//   text-align: center;
//   padding: 16px;
//   border-color: transparent;
//   border-radius: 10px;
//   background: $selected-color !important;
//   color: #fff;
//   text-transform: capitalize;
//   font-weight: bold;
//   outline: none;
//   &:focus {
//     box-shadow: $card-shadow;
//   }
//   margin-left: 1rem;
//   font-size: 1rem;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

.chart-container {
  display: flex;

  flex-direction: column;
}

@media (min-width: 900px) {
  .chart-container {
    flex-direction: row;
  }
}
