.logo-small {
  height: 20%;
  width: 20%;
  pointer-events: none;
}

input {
  display: block;
  color: black;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 18px;
}

label {
  line-height: 1.5;
  text-align: left;
  display: block;
  color: black;
  margin-bottom: 13px;
  margin-top: 20px;
  font-size: 18px;
  /* font-weight: 200; */
}

.btn {
  font-size: 18px;
}
